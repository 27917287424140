@import "sweetalert2/src/variables";

// BOX MODEL
$swal2-border-radius: 10px;
$swal2-box-shadow: none;

// BACKDROP
$swal2-backdrop-transition: rgba(0, 0, 0, 40) 0.3s;

// ICONS
$swal2-icon-animations: false;

// INPUT
$swal2-input-border-radius: 0;
$swal2-input-box-shadow: none;
$swal2-input-box-shadow-focus: none;
$swal2-input-transition: initial;

// BUTTONS
$swal2-confirm-button-border-radius: 5px;
$swal2-confirm-button-background-color: #0075c9;
$swal2-confirm-button-color: #ffffff;

$swal2-cancel-button-border-radius: 5px;
$swal2-cancel-button-background-color: #b5bec9;
$swal2-cancel-button-color: #ffffff;

$swal2-close-button-border-radius: 5px;
$swal2-close-button-background-color: #ffffff;
$swal2-close-button-color: #64748b;

// ANIMATIONS
$swal2-show-animation: fadeIn 0.2s cubic-bezier(0.65, 0, 0.35, 1);
$swal2-hide-animation: fadeOut 0.2s cubic-bezier(0.65, 0, 0.35, 1);

// CLOSE BUTTON
$swal2-close-button-transition: initial;

// TOASTS
$swal2-toast-show-animation: none;
$swal2-toast-hide-animation: none;
$swal2-toast-success-line-tip-animation: none;
$swal2-toast-success-line-long-animation: none;
$swal2-toast-border: 1px solid lighten($swal2-black, 85);
$swal2-toast-box-shadow: none;

// Fade-in animation
@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

// Fade-out animation
@keyframes fadeOut {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

@import "sweetalert2/src/sweetalert2";
